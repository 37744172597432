import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import IndexPageTemplate from '../templates/IndexPage.template'
import dataStateConstants from '../constants/dataState.constants'

const BiznesPage = () => {
  const { biznes } = dataStateConstants

  return (
    <Layout>
      <SEO title="RDB Group | Biznes" />
      <IndexPageTemplate
        initial={biznes}
      />
    </Layout>
  )
}

export default BiznesPage
